import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import { Button, Group, ButtonGroup } from '@vkontakte/vkui';
import { Icon12Add, Icon16Add, Icon24Add } from '@vkontakte/icons';

const Result = ({ id, next, groupId, vkAppId, resultUrl, message }) => {
	const shareStories = () => {
	    bridge.send('VKWebAppShowStoryBox', {"background_type": "image","url": resultUrl,"attachment": {"url": "https://vk.com/app"+vkAppId,"text": "open","type": "url"}}).then(data => {
	    	console.log(data);
	    }).catch(error => {
	    	console.log(error);
	    });
	};
	const shareApp = () => {
		bridge.send("VKWebAppShowWallPostBox", {
			"message": message,
			"attachments": ""
		});
	}
	const download = () => {
		bridge.send('VKWebAppDownloadFile', {"url": resultUrl,"filename": "coupon.jpg"}).then(data => {
			console.log(data);
		}).catch(error => {
			console.log(error);
		});
	}
	const add_to_group = () => {
    	window.open('https://vk.com/add_community_app.php?aid='+vkAppId, '_blank');
	};

	const [size, setSize] = useState("s");
	const [addBefore, setAddBefore] = useState(true);

  const buttonBefore = addBefore && (size === "s" ? (<Icon12Add />) : size === "m" ? (<Icon16Add />) : (<Icon24Add />));

	return (
		<Group id={id}>
			<div style={{ textAlign: 'center' }}>
				<div style={{ padding: '2px 12px 8px', fontSize: '24px', fontWeight: 'bold' }}></div>
				<img id="left" style={{ width: '90%', borderRadius: '10px'}} src={resultUrl} />
			</div>
			<div style={{ display: 'flex', justifyContent: 'center'}}>
				<ButtonGroup align="center" mode="vertical" gap="s" stretched={true} style={{ marginLeft: '5%', marginRight: '5%' }}>
					<Button appearance="positive" size="l" stretched onClick={add_to_group} before={buttonBefore}>Установить в сообщество</Button>
					<Button size="l" stretched onClick={download}>Скачать</Button>
					<Button size="l" stretched onClick={next} data-to="q1">Попробовать ещё раз</Button>
					<Button size="l" stretched onClick={shareStories}>Поделиться в истории</Button>
					<Button appearance="positive" size="l" stretched onClick={shareApp}>Поделиться на своей странице</Button>
				</ButtonGroup>
			</div>
		</Group>
);
}
export default Result;