import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Group, FormLayout, FormItem, FormLayoutGroup, Input, Div } from '@vkontakte/vkui';

const Q1 = ({ id, next, forms }) => {
	const [disabled, setDisabled] = useState(true);
	const [inputs, setInputs] = useState(Array.from({ length: forms[0].input.length }, () => null));

	const setInput = (index, value) => {
	  setInputs(prevInputs => {
	    const updatedInputs = [...prevInputs];
	    updatedInputs[index] = value;
	    return updatedInputs;
	  });
	};

	const onChange = (e) => {
		const { id, value } = e.target;
		const index = parseInt(id.split('-')[1]);

		if (index >= 0 && index <= forms[0].input.length) {
			setInput(index, value);
		}
	};

	const getDataTo = () => {
		let response = '';
		switch (forms.length) {
			case 1:
				response = 'result';
				break;
			case 2:
			case 3:
				response = 'q2';
				break;
		}
		return response;
	};

	useEffect(() => {
		setDisabled(inputs.some(input => input === null));
	}, [inputs]);

	return (
		<Group id={id}>
			<FormLayout>
				<FormItem>
					<Div>{forms[0].title}</Div>
					{forms[0].input.slice(0, 6).map((item, index) => (
					  <FormLayoutGroup key={index} mode="horizontal">
					    <FormItem top={item}>
					      <Input id={`input-${index}`} type="text" onChange={onChange} />
					    </FormItem>
					  </FormLayoutGroup>
					))}
				</FormItem>
				<FormItem>
					<Button disabled={disabled} size="l" stretched onClick={next} data-to={getDataTo()} data-value={inputs}>Далее</Button>
				</FormItem>
			</FormLayout>
		</Group>
);
}
export default Q1;