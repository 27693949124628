import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Group, FormLayout, FormItem, FormLayoutGroup, Div } from '@vkontakte/vkui';
import { ImageId1 } from '../img/ImageIds';

const Public = ({ id, next, query, image }) => {

	return (
		<Group id={id}>
			<FormLayout>
				<FormItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					<img id="left" style={{ width: '100%', borderRadius: '10px'}} src={image} />
					<FormLayoutGroup mode="horizontal">
						<Div>{query}</Div>
					</FormLayoutGroup>
					<Button size="l" stretched onClick={next} data-to="q1">Начать</Button>
				</FormItem>
			</FormLayout>
		</Group>
);
}
export default Public;