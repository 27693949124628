import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import axios from 'axios';

import { ANDROID, VKCOM, Text, Title, Avatar, usePlatform, Platform, Panel, PanelHeader, PanelHeaderBack, View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, ModalRoot, ModalPage, ModalCard, ModalPageHeader, PanelHeaderButton, ButtonGroup, Button, Group, CellButton, Placeholder } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { Icon24Dismiss, Icon56MoneyTransferOutline, Icon56NotificationOutline } from '@vkontakte/icons';

import Public from './panels/public';
import Q1 from './panels/q1';
import Q2 from './panels/q2';
import Q3 from './panels/q3';
import Result from './panels/result';

const MODAL_CARD_CHAT_INVITE = 'chat-invite';

const App = () => {
	const [scheme, setScheme] = useState('bright_light')
	const [popout, setPopout] = useState(null);
	const [activePanel, setActivePanel] = useState('public');
	const [vkAppId, setVkAppId] = useState(null);
	const [vkUserId, setVkUserId] = useState(null);
	const [vkGroupId, setVkGroupId] = useState(null);

	const [title, setTitle] = useState(null);
	const [query, setQuery] = useState(null);
	const [image, setImage] = useState(null);
	const [forms, setForms] = useState(null);
	const [groups, setGroups] = useState(null);
	const [message, setMessage] = useState(null);

	const [gavatar, setGavatar] = useState(null);
	const [gtitle, setGtitle] = useState(null);
	const [gtext, setGtext] = useState(null);


	const [groupId, setGroupId] = useState(null);
	const [join, setJoin] = useState(null);
	const [notification, setNotification] = useState(null);
	const [adshow, setAdshow] = useState(null);
	const [q1Value, setQ1Value] = useState(null);
	const [q2Value, setQ2Value] = useState(null);
	const [q3Value, setQ3Value] = useState(null);
	const [resultUrl, setResultUrl] = useState(null);

	const [activeModal, setActiveModal] = useState(null);
	const [modalHistory, setModalHistory] = useState([]);

	const platform = usePlatform();

	const hasHeader = platform !== VKCOM;


	const clearPopout = () => setPopout(null);
	const messfrom = e => {
		if(notification == 1){
			bridge.send('VKWebAppAllowMessagesFromGroup', {"group_id": groupId,"key": "app_topslike"}).then(data => {
				joingroup();
			}).catch(error => {
				joingroup();
			});
		} else {
			joingroup();
		}
		return true;
	};
	const joingroup = e => {
		if(join == 1){
			bridge.send('VKWebAppJoinGroup', {"group_id": groupId}).then(data => {
				setPopout(<ScreenSpinner state="loading" />);
				setTimeout(() => {
					setActivePanel('q1');
					clearPopout();
				}, 1000);
			}).catch(error => {
				setPopout(<ScreenSpinner state="loading" />);
				setTimeout(() => {
					setActivePanel('q1');
					clearPopout();
				}, 1000);
			});
		} else {
			setPopout(<ScreenSpinner state="loading" />);
			setTimeout(() => {
				setActivePanel('q1');
				clearPopout();
			}, 1000);
		}
	};
	const joinGroup = e => {
		bridge.send('VKWebAppJoinGroup', {"group_id": groupId}).then(data => {
			setActiveModal(null);
		}).catch(error => {});
	}
	const showRek = e => {
		if(adshow == 1){
			bridge.send("VKWebAppCheckNativeAds", {"ad_format": "interstitial"});
			bridge.send("VKWebAppShowNativeAds", {ad_format: "interstitial"}).then(data => {
				setTimeout(setActivePanel(e), 2000);
			} ).catch(error => {
				setTimeout(setActivePanel(e), 1000);
			} );
		} else {
			setTimeout(setActivePanel(e), 1000);
		}
	};
	const next = e => {
		const to = e.currentTarget.dataset.to;
		switch (to) {
			case 'q1':
				setPopout(<ScreenSpinner state="loading" />);
				setTimeout(() => {
					setActivePanel(to);
					clearPopout();
				}, 1000);
				break;
			case 'q2':
				setPopout(<ScreenSpinner state="loading" />);
				setQ1Value(e.currentTarget.dataset.value);
				setTimeout(() => {
					showRek(to);
					clearPopout();
				}, 1000);
				break;
			case 'q3':
				setPopout(<ScreenSpinner state="loading" />);
				setQ2Value(e.currentTarget.dataset.value);
				setTimeout(() => {
					showRek(to);
					clearPopout();
				}, 1000);
				break;
			case 'result':
				setPopout(<ScreenSpinner state="loading" />);
				const q3Value = e.currentTarget.dataset.value;
				setQ3Value(q3Value);
				const eventData = { q1Value, q2Value, q3Value };
				requestData(eventData);
				setTimeout(() => {
					showRek(to);
					clearPopout();
					if(join === 1){
						setActiveModal(MODAL_CARD_CHAT_INVITE);
					}
				}, 1000);
				break;
		}
	};
	const requestData = async e => {
		const config = { headers: {'Content-Type': 'application/json' }};
		const data = { 'method': 'post', 'vk_app_id': vkAppId, 'vk_user_id': vkUserId, 'one': e.q1Value, 'two': e.q2Value, 'three': e.q3Value, 'mold_id': 1 };
		try {
			const response = await axios.post('https://api.topslike.ru/apps/data', data, config);
			const { form, url } = response.data;
			setResultUrl(url);
		} catch (error) {
		}
	};
	const renderBackButton = e => {
		if (activePanel.startsWith('q') && parseInt(activePanel.slice(1)) <= 6) {
			return <PanelHeaderBack onClick={() => nextBack(parseInt(activePanel.slice(1)))} />;
		} else {
			return null;
		}
	};
	const nextBack = (e) => {
		switch (e) {
			case 1:
				setPopout(<ScreenSpinner state="loading" />);
				setTimeout(() => {
					setActivePanel('public');
					clearPopout();
				}, 1000);
				break;
			default:
				setPopout(<ScreenSpinner state="loading" />);
				setTimeout(() => {
					setActivePanel('q' + (e-1).toString());
					clearPopout();
				}, 1000);
				break;
		}
	};
	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

  const modal = (
  	<ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
			<ModalPage
          id={MODAL_CARD_CHAT_INVITE}
          onClose={() => setActiveModal(null)}
          settlingHeight={75}
          style={{ marginBottom: '20px' }}
			>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '12px 16px' }}>
				<Avatar size={75} src={gavatar} />
				<Title level="2" style={{ marginTop: 5 }}>{gtitle}</Title>
				<Text weight="semibold" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{gtext}</Text>
				<Group style={{ padding: '20px' }}>
					<ButtonGroup size="l" stretched>
						<Button key="decline" size="l" mode="secondary" stretched onClick={() => setActiveModal(null)}>Отказаться</Button>
						<Button key="ok" size="l" mode="primary" stretched onClick={() => joinGroup()}>Да, хорошо</Button>
					</ButtonGroup>
				</Group>
			</div>
			</ModalPage>
		</ModalRoot>
  );


  useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				setScheme(data.scheme)
			}
		});

		async function fetchData() {
			const query = new URLSearchParams(window.location.search);
			const vk_app_id = query.get('vk_app_id');
			const vk_user_id = query.get('vk_user_id');
			const vk_group_id = query.get('vk_group_id');

			setVkAppId(vk_app_id);
			setVkUserId(vk_user_id);
			setVkGroupId(vk_group_id);

			const config = { headers: {'Content-Type': 'application/json' }};
			const data = {
		        'method': 'info',
		        'vk_app_id': vk_app_id,
		        'vk_user_id': vk_user_id,
		        'vk_group_id': vk_group_id
			};

			try {
		        const response = await axios.post('https://api.topslike.ru/apps/get', data, config);
		        const { title, query, image, forms, groups, message, id, join, notification, adshow } = response.data.response;
		        setTitle(title);
		        setQuery(query);
		        setImage(image);
		        setForms(forms);
		        setMessage(message);
		        setGroupId(id);
		        setJoin(join);
		        setNotification(notification);
		        setAdshow(adshow);
		        setGroups(groups);

		        setGavatar(groups.avatar);
		        setGtitle(groups.title);
		        setGtext(groups.text);

		    } catch (error) {
		    }
		}
		bridge.send('VKWebAppShowBannerAd', {banner_location: 'bottom'});

		fetchData();
	}, []);


	return (
		<ConfigProvider scheme={scheme}>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout modal={modal} popout={popout} style={{ justifyContent: "center" }} header={hasHeader && <PanelHeader separator={false} />}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Panel id={activePanel}>
									<PanelHeader left={renderBackButton()}>{title}</PanelHeader>
										<View activePanel={activePanel}>
											<Public id='public' next={next} query={query} image={image} />
											<Q1 id='q1' next={next} forms={forms} />
											<Q2 id='q2' next={next} forms={forms} />
											<Q3 id='q3' next={next} forms={forms} />
											<Result id='result' next={next} groupId={groupId} vkAppId={vkAppId} resultUrl={resultUrl} message={message} />
										</View>
								</Panel>
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
